<template>
  <v-dialog width="800" v-model="dialog" scrollable persistent>
    <v-card :loading="loading">
      <v-system-bar window>
        {{ title }} anpassen<v-spacer />
        <v-icon @click="close()">mdi-close</v-icon>
      </v-system-bar>
      <v-card-text class="ma-0 pa-0">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container v-if="item">
            <v-row class="my-0" align="center">
              <v-col>
                <PersonItem v-model="item.student" />
              </v-col>

              <v-col>
                <v-switch inset v-model="item.physicalEducation">
                  <template v-slot:label
                    ><v-icon left>mdi-run</v-icon> Sportdispensation
                  </template>
                </v-switch>
              </v-col>
            </v-row>

            <v-row class="my-0">
              <v-col>
                <v-text-field label="Grund" v-model="item.description" />
              </v-col>
            </v-row>

            <v-row class="my-0">
              <v-col cols="auto">
                <v-switch inset v-model="allDay">
                  <template v-slot:label> ganztägig </template>
                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-help-circle-outline</v-icon
                        >
                      </template>
                      <span
                        >Die Dispensation gilt von morgens früh bis abends
                        spät!</span
                      >
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-col>
              <v-col>
                <v-row class="mb-0">
                  <v-col>
                    <DateInput
                      v-model="item.startDate"
                      label="von Tag"
                      required
                    />
                  </v-col>
                  <v-col>
                    <TimeInput
                      :disabled="allDay"
                      :label="allDay ? 'morgens' : 'ab'"
                      v-model="item.startTime"
                      :required="!allDay"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col>
                    <DateInput
                      v-model="item.endDate"
                      label="bis Tag"
                      required
                    />
                  </v-col>
                  <v-col>
                    <TimeInput
                      :disabled="allDay"
                      :label="allDay ? 'abends' : 'bis'"
                      v-model="item.endTime"
                      :required="!allDay"
                    />
                  </v-col>
                </v-row>
                <v-alert text type="error" v-if="dateError"
                  >Der Beginn der Entschuldigung darf nicht vor dem Ende dieser
                  liegen!</v-alert
                >
              </v-col>
            </v-row>

            <template v-if="absences.length > 0">
              <v-alert type="error" border="left" text prominent>
                Es gibt Konflikte mit den folgenden Absenzen. Diese müssen
                zuerst manuell gelöscht werden!
              </v-alert>
              <v-card outlined>
                <v-system-bar>Konflikte (Absenzen)</v-system-bar>
                <AbsenceList :items="absences" @updated="checkAbsences" />
              </v-card>
            </template>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn @click="trash()" color="danger" text class="mr-2">Löschen</v-btn>

        <v-spacer />
        <v-btn @click="close(false)" text>Abbrechen</v-btn>
        <v-btn
          @click="save()"
          :disabled="!valid || absences.length > 0"
          color="primary"
          text
          :loading="saving || checking"
          >Prüfen & Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import TimeInput from "common/components/TimeInput.vue";
import DateInput from "common/components/DateInput.vue";
import PersonItem from "common/components/PersonItem.vue";

import AbsenceList from "common/components/AbsenceList.vue";

export default defineComponent({
  name: "DispensationEdit",
  components: {
    AbsenceList,
    TimeInput,
    DateInput,
    PersonItem,
  },
  props: ["id", "term", "schoolClass"],
  data() {
    return {
      absences: [],
      allDay: false,
      title: "Dispensation",
      dialog: true,
      errorMessage: null,
      noAbsences: false,
      checking: false,
      saving: false,
      loading: false,
      item: {},
      valid: false,
    };
  },
  computed: {
    dateError() {
      if (!this.item.startDate || !this.item.endDate) {
        return false;
      }
      return (
        this.item.startDate + this.item.startTime >
        this.item.endDate + this.item.endTime
      );
    },
    saveable() {
      return (
        this.item.startDate &&
        this.item.endDate &&
        this.noAbsences &&
        !this.dateError
      );
    },
  },
  watch: {
    allDay() {
      if (this.allDay) {
        this.item.startTime = null;
        this.item.endTime = null;
      }
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    async checkAbsences() {
      this.checking = true;
      this.absences = await this.apiList({
        resource: "absence/absence",
        query: `startDate=${this.item.startDate}&startTime=${
          this.item.startTime
        }&endDate=${this.item.endDate}&endTime=${this.item.endTime}&student=${
          this.item.student.id
        }${this.item.physicalEducation ? "&physicalEducation=true" : ""}`,
      });
      this.noAbsences = this.absences.length == 0;

      this.checking = false;
    },
    close() {
      this.$router.push({ name: "Dispensations" });
    },
    async save() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      await this.checkAbsences();
      if (this.saveable) {
        this.saving = true;
        this.errorMessage = null;
        await this.apiPut({
          resource: "absence/dispensation",
          data: this.item,
          onError: (response) => (this.errorMessage = response),
        });
        this.saving = false;
        if (!this.errorMessage) {
          this.$emit("update");
          this.close();
        }
      }
    },
    async trash() {
      if (
        await this.$root.confirm({
          message: `Soll diese Dispensation gelöscht werden?`,
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "absence/dispensation",
          id: this.item.id,
        });
        this.$emit("update");
        this.close();
      }
    },
    async fetchData() {
      this.loading = true;
      this.item = {};
      if (this.id) {
        this.item = await this.apiGet({
          resource: "absence/dispensation",
          id: this.id,
        });
        this.title = this.item.physicalEducation
          ? "Sportdispensation"
          : "Dispensation";
        this.allDay = !this.item.startTime && !this.item.endTime;
      }
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
